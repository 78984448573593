import Cookies from 'universal-cookie';
import FlashlightOff from '../../assetts/svg/flashlight-off';
import FlashlightOn from '../../assetts/svg/flashlight-on';
import useSettingsStore from '../../hooks/settings';

function Flashlight(props: any) {
  const { className } = props;
  const { isFlashlightOn, setIsFlashlightOn } = useSettingsStore();

  const cookies = new Cookies();

  const toggleFlashlight = () => {
    cookies.set('isFlashlightOn', !isFlashlightOn, { path: '/' });
    setIsFlashlightOn(!isFlashlightOn);
  };

  return (
    <div className={className} onClick={toggleFlashlight}>
      {
        isFlashlightOn ?
        <FlashlightOn className="setting-icon flashlight" /> :
        <FlashlightOff className="setting-icon flashlight" />
      }
    </div>
  );
}

export default Flashlight;
