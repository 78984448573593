import Download from '../../assetts/svg/download';
import Education from '../../assetts/svg/education';
import Email from '../../assetts/svg/email';
import Github from '../../assetts/svg/github';
import LinkedIn from '../../assetts/svg/linkedin';
import Printer from '../../assetts/svg/printer';
import Wave from '../../assetts/svg/wave';
import Work from '../../assetts/svg/work';
import NavData from '../../data/nav';
import Flashlight from '../flashlight/flashlight';
import Mode from '../mode/mode';
import './nav.css';

function Nav(props: any) {
  const { className, closeNav } = props;

  const toggleNav = () => {
    closeNav()
  }

  const jumpTo = (location: string) => {
    toggleNav()
    return window.location.replace(location);
  }; 

  const printPage = (event: any) => {
    console.log(111, event);
    window.print();
  }

  const renderIcon = (id: string) => {
    switch(id) {
      case 'about':
        return <Wave className='nav-icon' />;
      case 'experience':
        return <Work className='nav-icon' />;
      case 'education':
        return <Education className='nav-icon' />;
      case 'linkedin':
        return  <LinkedIn className='nav-icon' />;
      case 'email':
        return <Email className='nav-icon' />;
      case 'github':
        return <Github className='nav-icon' />;
      default:
        return;
    }
  }

  const renderContactData = NavData.map((contact: any, index: number) => {
    const { external, id, label, url } = contact;

    if(!id || !label || !url) {
      return null;
    }

    if(!external) {
      return (
        <div className='nav-item' key={index} onClick={() => jumpTo(url)}>
          {renderIcon(id)}
          {label}
        </div>
      )
    }

    return (
      <a className='nav-item' key={index} href={url}  onClick={toggleNav} rel='noopener noreferrer' target='_blank'>
        {renderIcon(id)}
        {label}
      </a>
    )
  });

  return (
    <div className={className + ' nav'}>
      <div className='title nav-title'>Menu</div>
      {renderContactData}
      <div className="settings">
        <div onClick={() => window.print()}>
          <Printer className="setting-icon" />
        </div>
        {/* <Download /> */}
        <Mode />
        <Flashlight />
      </div>
    </div>
  );
}

export default Nav;
