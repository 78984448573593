import './experience.css';
import ExpereienceData from '../../data/experience';

function Experience(props: any) {
  const { className } = props;

  const renderLinks = (links: any) => {
    if (!links || !links.length) return;

    return links.map((link: any, index: number) => (
      <div key={index} className="links">
        <span>Visit: </span><a className="pill button" href={link.url} rel="noopener noreferrer" target="_blank">{link.label}</a>
      </div>
    ));
  }

  const renderTechnologies = (technologies: string[]) => {
    if (!technologies.length) return;

    return technologies.map((tech: string, index: number) => (
      <div className="pill" key={index}>{tech}</div>
    ))
  }

  const renderDescription = (description: string[]) => {
    if(!description.length) return;

    return description.map((info: string, index: number) => (
      <div className='info' key={index}>{info}</div>
    ));
  }

  const renderExperience = ExpereienceData.map((job, index) => {
    const { company, date, description, links, technologies, title } = job;

    if (!company || !date || !title || !description || !technologies) {
      return null;
    }
    
    const pageBreakIndexes = [1,3];
    const isPageBreak = pageBreakIndexes.includes(index);

    return (
      <div className={'card ' + (isPageBreak ? 'pagebreak' : '')} key={company}>
        <div className="card-header">
            <div>{title} - {company}</div>
            <div className="subtitle">{date}</div>
        </div>
        <div className="technologies">{renderTechnologies(technologies)}</div>
        <div className="description">{renderDescription(description)}</div>
        <div>{renderLinks(links)}</div>
      </div>
    )
  });

  return (
    <div className={className} id="experience">
      <h3 className='title'>Experience</h3>
      {renderExperience}
    </div>
  );
}

export default Experience;
