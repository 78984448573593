const ExpereienceData = [
    {
      "company": "Walmart Global Tech",
      "title": "Senior Software Engineer",
      "date": "Jul 2022 - Aug 2024",
      "description": [
        "Led a team of engineers to rebrand a recently acquired telehealth application to represent the Walmart Health Virtual Care brand.",
        "Transformed existing legacy front-end applications in angular into a modern componentized structure for better maintainability and use of latest features.",
        "Identified key failure points within user flows and redesigned the approach to increase conversion and better the overall user experience.",
        "Identified security weak points within a suite of applications and led approaches to resolve the issues at the source.",
        "Implemented real user monitoring & logging to track the core vitals of the application to identify and resolve outages quicker.",
        "Upgraded multiple frameworks and packages to utilize the latest supported versions. Identified opportunities to improve the team's peer review process to increase visibility into code changes. Improved multiple build pipelines to ensure that the code being deployed was reliable and efficient."
      ],
      "links": [
        {
          "url": "https://patient.memd.me/",
          "label": "Walmart Virtual Care"
        }
      ],
      "technologies": [
        "Angular",
        "Javascript",
        "HTML",
        "SCSS",
        "BitBucket",
        "DataDog",
        "Ionic"
      ]
    },
    {
      "company": "ThredUp",
      "title": "Software Engineer",
      "date": "Jan 2020 - Jun 2022",
      "description": [
        "Led the migration of multiple core company features from our legacy platform, including: subscription box services, core market checkout, and aftermarket services.",
        "Collaborated with our design team and advocated for the design of fluid components, a core set of styles and a scalable way for our engineering team to implement standards.",
        "Designed foundational components, layouts, and logic layers for the company’s Content Management System used to build target landing pages using Figma and implemented in React.",
        "Implemented dynamic pages for targeted influencer landing pages, sales, promotions, and subsets of users.",
        "Designed & implemented oAuth and various payment providers to a dynamic checkout page for the company’s core marketplace, subscription box service, and aftermarket platform.",
        "Integrated A/B testing into core flow of our application to increase user purchase rates.",
        "Identified key business metrics and application performance data to key stakeholders to influence future features.",
        "Led a diverse group of engineers, designers, and product managers to challenge the way the company thinks about e-commerce with 1 of 1 items in a company hackathon. The team went on to win the hackathon and processes in all departments of the company are being refactored to integrate our model."
      ],
      "links": [
        {
          "url": "https://www.thredup.com/",
          "label": "ThredUp.com"
        }
      ],
      "technologies": [
        "React",
        "Javascript",
        "HTML",
        "CSS",
        "Tailwind",
        "GitHub",
        "Splunk",
        "Docker"
      ]
    },
    {
      "company": "Drivetime",
      "title": "Software Engineer",
      "date": "Jul 2017 - Jan 2020",
      "description": [
        "Led company initiatives to develop & maintain DriveTime’s consumer facing website by providing technical advise on feature development.",
        "Promoted closer partnerships between design and engineering teams in order to define company design and engineering principles.",
        "Devised A/B tests to optimize UI/UX across the company's consumer website.",
        "Implemented micro animations to increase site usability, RUM metrics, and performance.",
        "Advocated for responsive and fluid design in the company's mobile website which led to the depreciation of our stand alone desktop site in favor of a single application.",
        "Implemented lazy loading in angular app to improve page performance and metrics.",
        "Led an initiative to optimize all image assets across the company's website to improve Lighthouse scores and FMP.",
        "Integrated the company's website with Cloudflare’s Edge CDN."
      ],
      "links": [
        {
          "url": "https://www.drivetime.com/",
          "label": "DriveTime.com"
        }
      ],
      "technologies": [
        "Angular",
        "Javascript",
        "HTML",
        "SCSS",
        "Asure",
        "Splunk",
        "Docker"
      ]
    },
    {
      "company": "Atticue",
      "title": "Fullstack Engineer",
      "date": "Jul 2015 - Jun 2017",
      "description": [
        "Designed and developed a large scale application that curated the world’s event data.",
        "Charged with the responsibility for all front-end, public, and internal products.",
        "Led multiple initiatives to design, develop, and optimize company products and tools.",
        "Refactored existing company products to remove outdated technologies.",
        "Advocated for a SCRUM work environment in order for our small startup to thrive with the ever growing lists of objectives.",
        "Refactored the company's Node.js REST API to drive content and control multiple clients (web, iOS & Android) interface from a single source."
      ],
      "technologies": [
        "Javascript",
        "HTML",
        "CSS",
        "AWS"
      ]
    }
  ]
  
  export default ExpereienceData;
  