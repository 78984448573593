import { useEffect } from 'react';
import Close from '../../assetts/svg/close';
import Menu from '../../assetts/svg/menu';
import useSettingsStore from '../../hooks/settings';
import Nav from '../nav/nav';
import './header.css';
import Email from '../../assetts/svg/email';
import Phone from '../../assetts/svg/phone';

function Header(props: any) {
  const { className } = props;
  const { isNavOpen, setIsNavOpen, isMenuControlVisible, setIsMenuControlVisible} = useSettingsStore();

  const determineIfMobile = () => {
    const { innerWidth: width } = window;
    const isMobile = width <= 768;
    return isMobile;
  }

  useEffect(() => {
    const handleResize = () => {
      if(determineIfMobile()) {
        setIsMenuControlVisible(true);
        setIsNavOpen(false);
      } else {
        setIsMenuControlVisible(false);
        setIsNavOpen(true);
      }
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  const toggleNav = () => {
    if(determineIfMobile()) {
      setIsNavOpen(!isNavOpen);
    }
  }

  const renderMenuControl = () => (
    <div className='menu' onClick={toggleNav}>
      {!isNavOpen && <Menu className='hamburger'/>}
      {isNavOpen && <Close className='hamburger' />}
    </div>
  )

  return (
    <div className={className}>
      <h1 className='page-title'>Morgan Lee</h1>
      <h2 className='sub-title'>Senior Front-end Engineer</h2>
      <h3 className='tagline'>Austin based front-end engineer with a passion for user experience and design.</h3>
      <div className='print-only-contact'>
        <div className='print-item'><Phone className='print-icon' />480 370 2343</div>
        <div className='print-item'><Email className='print-icon' />mtlee3@gmail.com</div>
      </div>
      <div className='navigation-container'>
        {isMenuControlVisible && renderMenuControl()}
        <Nav className={isNavOpen ? 'show-nav' : 'hide-nav'} closeNav={toggleNav}/>
      </div>
    </div>
  );
}

export default Header;
