const NavData = [
    {
      "id": "about",
      "label": "About",
      "url": "#about",
      "external": false
    },
    {
      "id": "experience",
      "label": "Experience",
      "url": "#experience",
      "external": false
    },
    {
      "id": "education",
      "label": "Education",
      "url": "#education",
      "external": false
    },
    {
      "id": "linkedin",
      "label": "LinkedIn",
      "url": "https://www.linkedin.com/in/morgantaylor193/",
      "external": true
    },
    {
      "id": "email",
      "label": "Email",
      "url": "mailto:morgan.lee.resume@gmail.com",
      "external": true
    },
    {
      "id": "github",
      "label": "Github",
      "url": "https://github.com/morgantaylor193",
      "external": true
    }
  ]
  
  export default NavData;
  