import './about.css';

function About(props: any) {
  const { className } = props;

  return (
    <div className={className} id="about">
      <h3 className="title">About</h3>
      <div className="wrapper">
        <div className="about">Passionate about detail, performance, and building sustainable solutions for the future with today's technologies.</div>
        <div className="about">Over the past <span className="bold">9 years</span>, I have gained extensive experience across a wide range of industries, including <span className="bold">Events, Automotive, Fashion, and Health</span>. This journey has provided me with the unique opportunity to grow and adapt in various professional settings, from <a className="bold" href='https://thredup.com/' rel="noopener noreferrer" target='_blank'>startups</a> to one of the <a className="bold" href='https://tech.walmart.com/content/walmart-global-tech/en_us.html' rel="noopener noreferrer" target='_blank'>world's largest corporations</a>.</div>
        <div className="about">My days are driven by the challenge of <span className="bold">optimizing user experience and application performance</span>. I thrive on finding the perfect balance between design and engineering, creating functional and aesthetically pleasing UIs while ensuring robust and sustainable development.</div>
        <div className="about">When I'm not at my computer, I enjoy spending time with my wife and dogs, hitting the race track with friends, or working on various DIY projects around the house.</div>
      </div>
    </div>
  );
}

export default About;
