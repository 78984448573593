import Cookies from 'universal-cookie';
import DarkMode from '../../assetts/svg/dark-mode';
import LightMode from '../../assetts/svg/light-mode';
import useSettingsStore from '../../hooks/settings';

function Mode(props: any) {
  const { className } = props;
  const cookies = new Cookies();
  const { isLightMode, setIsLightMode } = useSettingsStore();

  const toggleMode = () => {
    cookies.set('isLightMode', !isLightMode, { path: '/' });
    setIsLightMode(!isLightMode);
  };

  return (
    <div className={className} onClick={toggleMode}>
      {
        isLightMode ?
        <DarkMode className="setting-icon" /> :
        <LightMode className="setting-icon" />
      }
    </div>
  );
}

export default Mode;
