import ACU from '../../assetts/svg/acu';
import Oswego from '../../assetts/svg/oswego';
import Tafe from '../../assetts/svg/tafe';
import EducationData from '../../data/education';
import './education.css';

function Education(props: any) {
  const { className } = props;


  const renderEducation = EducationData.map((insitituion, index) => {
    const { course, date, id, location, name } = insitituion;

    if (!course || !date || !id || !location || !name) {
      return null;
    }
    
    return (
      <div className='card education' key={name}>
        <div className='card-header education'>
            <div>{name} - {location}</div>
            <div className='subtitle'>{date}</div>
            <div className='course-info'>{course}</div>
        </div>
        {id === 'ACU' && <ACU className='logo'/>}
        {id === 'OSWEGO' && <Oswego className='logo'/>}
        {id === 'TAFE' && <Tafe className='logo'/>}
      </div>
    )
  });

  return (
    <div className={className} id='education'>
      <h3 className='title'>Education</h3>
      {renderEducation}
    </div>
  );
}

export default Education;
