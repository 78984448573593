import './App.css';
import Content from './components/content/content';
import Header from './components/header/header';
import useSettingsStore from './hooks/settings';
import './styles/print.css';

function App() {
  const isLightMode = useSettingsStore((state) => state.isLightMode)
  const isFlashlightOn = useSettingsStore((state) => state.isFlashlightOn)

  const flasshLightEffect = (event: any) => {
    const canvas = document.getElementById('flashlight');

    if(canvas) {
      const locationY = event.clientY.toString();
      const locationX = event.clientX.toString();
      
      const style = 'radial-gradient(300px at ' + locationX + 'px ' + locationY + 'px, rgba(94, 234, 212, 0.10), transparent 80%)';

      canvas.style.background = isFlashlightOn ? style : ''; 
    }
  };

  // ToDo:
  // Clicking on email should copy to clipboard and show toast success.
  // Active section header color, About/Experience
  // Mobile Nav
  // Make mode dynamic based on time of day.
    // Add setting to set this perminatly.
    // This can be done though a modal within the mode component. Logic would remove the button??

  return (
    <main className={'app ' + (isLightMode ? 'lightmode' : 'darkmode')} onMouseMove={flasshLightEffect}>
      <div id="flashlight" className="flashlight-effect"></div>
      <div className="resume">
        <div className="side-bar">
          <Header />
        </div>
        <Content className="content" />
      </div>
    </main>
  );
}

export default App;
