import Cookies from 'universal-cookie';
import { create } from 'zustand'

interface SettingsState {
  isLightMode: boolean;
  setIsLightMode: (isLightMode: boolean) => void;
  isFlashlightOn: boolean;
  setIsFlashlightOn: (isFlashlightOn: boolean) => void;
  isNavOpen: boolean;
  setIsNavOpen: (isNavOpen: boolean) => void;
  isMenuControlVisible: boolean;
  setIsMenuControlVisible: (isMenuControlVisible: boolean) => void;
}

function determineIfMobile() {
  const { innerWidth: width } = window;
  const isMobile = width <= 768;
  return isMobile;
}

const cookies = new Cookies();
const initMode = cookies.get('isLightMode') ?? false;
const initFlashlight = cookies.get('isFlashlightOn') ?? determineIfMobile();
const initNavOpen = !determineIfMobile();
const initIMenuControlVisible = determineIfMobile();

const useSettingsStore = create<SettingsState>((set) => ({
  isLightMode: initMode,
  setIsLightMode: (value) => set({isLightMode: value}),
  isFlashlightOn: initFlashlight,
  setIsFlashlightOn: (value) => set({isFlashlightOn: value}),
  isNavOpen: initNavOpen,
  setIsNavOpen: (value) => set({isNavOpen: value}),
  isMenuControlVisible: initIMenuControlVisible,
  setIsMenuControlVisible: (value) => set({isMenuControlVisible: value})
}))

export default useSettingsStore;