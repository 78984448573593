import About from '../about/about';
import Education from '../education/education';
import Experience from '../experience/experience';
import './content.css';

function Content(props: any) {
  const { className } = props;

  return (
    <div className={className}>
      <About className="section"/>
      <Experience className="section"/>
      <Education className="section" />
      {/* <Projects className="section"/> */}
    </div>
  );
}

export default Content;
